import React from "react";

function WhatIsDS() {
  return (
    <div className="what-is-ds">
      <div className="what-is-ds-title d-flex flex-wrap flex-lg-nowrap">
        <h1>What is A Digital Share?</h1>
        <h6>What is a Digital Share?</h6>
      </div>
      <div className="what-is-ds-content">
        <p>
          One of the optimal uses of blockchain technology is where shares are
          issued in the form of Non-Fungible Token (NFT) where each share in a
          company, for example, represents one Digital Share (NFT).
          <br />
          The owner can receive the Digital Share (NFT) in their wallet as proof
          of ownership and trade them (or fractions of them) for other digital
          assets with near instant settlement. This method is one of the best
          ways to ensure the ease of transferring or selling shares, in addition
          to receiving monthly / quarterly profits to your wallet on autopilot.
          <br />
          Also, blockchain technology ensures that each shareholder has complete
          transparency and ability to follow any movement on the share pool.
          <br />
          One of the advantages of the smart contract is that it defines laws
          that cannot be changed and acts accordingly.
        </p>
      </div>
    </div>
  );
}

export default WhatIsDS;
