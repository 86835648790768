import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Buy from "./components/Buy";
import Purchased from "./components/Purchased";
import Rocket from "./components/Rocket";
import Television from "../../../assets/television.png";
import DSAdvantages from "./components/DSAdvantages";
import TeleverseDS from "./components/TeleverseDS";
import WhatIsDS from "./components/WhatIsDS";

function index(props) {
  return (
    <Col className="live-presale-max-width mt-5">
      <Row className="justify-content-center ">
        <Col
          xs={12}
          sm={10}
          lg={6}
          className="d-flex flex-column justify-content-between"
        >
          <Rocket {...props} />
        </Col>
        <Col xs={12} sm={10} lg={4}>
          <Buy />
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col xs={12} sm={10} lg={6}>
          <Purchased />
        </Col>
        <Col
          xs={12}
          sm={10}
          lg={4}
          className="d-flex align-items-center justify-content-center mw-100"
        >
          {/* <Image src={Television} alt={"television photo"} /> */}
          <img src={Television} alt="imgView" className="imgView" />
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col xs={12} sm={10}>
          <WhatIsDS />
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col xs={12} sm={10} lg={6}>
          <DSAdvantages />
        </Col>
        <Col xs={12} sm={10} lg={4} className=" justify-content-center">
          <TeleverseDS />
        </Col>
      </Row>
    </Col>
  );
}

export default index;
