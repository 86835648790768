function TeleverseDS() {
  return (
    <div className="mb-ds">
      <div className="what-is-ds-title">
        <h1>Venus Digital Share Advantages:</h1>
      </div>
      <div>
        <p>
          The initial digital share offering will be for 10 million shares
          valued at 1 dollar.
          <br />
          <br />
          Target is to sell 20% (2 million shares) to achieve the project
          vision.
        </p>
      </div>
    </div>
  );
}

export default TeleverseDS;
