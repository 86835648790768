import { ethers } from "ethers";
import { useContext, useEffect } from "react";
import { AppContext } from "../scripts/context";
import toast from "react-hot-toast";
import {
  A_SECTION,
  B_SECTION,
  CONNECT_METAMASK,
  C_SECTION,
  D_SECTION,
  E_SECTION,
  F_SECTION,
  G_SECTION,
  HTTP_PER_PAGE,
  PER_PAGE,
} from "../constants";
import { endpoints } from "../utils/networks";
import axiosInstance, { request } from "../admin/plugins/axios";
import {
  getPaginationAttributes,
  GetLocalStorag,
  SaveLocalStorag,
} from "../Helper/Helper";
/*  This custome hook for Connect Wallet by MetaMask | khaled Mofeed | 5/12/2022 */
export function useConnectMetaMask({ setConnectWalletModal }) {
  /*  import     getWeb3,
    setState,
    TOKEN_User,
    setTOKEN_User,
    setUserData,
    initState,
    setFollowData,
    setLoading, from context AppContext | khaled Mofeed | 5/12/2022 */
  const {
    getWeb3,
    setState,
    TOKEN_User,
    setTOKEN_User,
    setUserData,
    initState,
    setFollowData,
    loading,
    setLoading,
    setEvent,
    setEventFillter,
    perPage,
    page,
    setPagination_options,
    setEventCategories,
    CategoriesId,
    location,
    date_From,
    date_To,
    keyword,
    history,
    owned,
    setAllNftFillter,
    setPagination_Options,
    per_Page,
    inPage,
    fillterKeyword,
    statusNft,
    price_from,
    price_to,
    condition,
    CategoriesIdNft,
    setNft_A_Section,
    setNft_B_Section,
    setNft_C_Section,
    setNft_D_Section,
    setNft_E_Section,
    setNft_F_Section,
    setNft_G_Section,
    setOwnerOfNft,
    setPagination_OptionsOwn,
    inPageOwn,
    updateNetWork,
    chainId,
    isToken,
    setIsToken,
  } = useContext(AppContext);
  let message = "";
  /*  This function to return address,message,signature from MetaMask ,use to get the user token for api | khaled Mofeed  */
  const getSignWithMetaMaskData = async (e) => {
    await CONNECT_METAMASK.send("eth_requestAccounts");
    let provider = new ethers.providers.Web3Provider(CONNECT_METAMASK);
    const updateAndGetMessage = async () => {
      const response = await request(
        endpoints.TeleversUserApi.GetSignMessage.method,
        endpoints.TeleversUserApi.GetSignMessage.url
      );
      return response.data.data.message;
    };

    const message = await updateAndGetMessage();
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();
    return {
      wallet_address: address,
      message: message,
      signature: signature,
    };
  };
  /*  function for submit Connect wallet by MetaMask | khaled Mofeed | 5/12/2022 */
  const submitConnectMetaMask = async () => {
    /*  check if MetaMask extintion is not installed | khaled Mofeed | 5/12/2022 */
    if (!CONNECT_METAMASK) {
      toast.error(
        "Metamask is not installed, please install it to complete the process"
      );
      return;
    }
    setLoading(true);
    let data = {};
    /*  after check if MetaMask extintion is installed , setState , set address and method and set getWeb3 | khaled Mofeed | 5/12/2022 */
    try {
      data = await getSignWithMetaMaskData();
      setState({ loading: true });
      getWeb3(data.wallet_address[0]);
    } catch (error) {
      setState({ loading: false });
      setLoading(false);
      return;
    }
    await request(
      endpoints.TeleversUserApi.RegisterCheckUserAddress.method,
      endpoints.TeleversUserApi.RegisterCheckUserAddress.url,
      data
    )
      .then((res) => {
        SaveLocalStorag("UserToken", res.data.data.token);
        setTOKEN_User(res.data.data.token);
        setIsToken(true);
        SaveLocalStorag("connectStatus", "1");
        axiosInstance.defaults.headers.common["Authorization"] =
          res.data.data.token.includes("Bearer")
            ? res.data.data.token
            : `Bearer ${res.data.data.token}`;

        setLoading(false);
        toast.success(res.data.message);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.data.errors) {
          toast.error("Invalid credentials");
          return;
        }
        toast.error(error.response.data.message);
      });
  };
  /*  This function to return the profile data of this address of MetaMask ,by using the user token returned from getSignWithMetaMaskData function | khaled Mofeed  */
  const getProfileData = async () => {
    axiosInstance.defaults.headers["Authorization"] = `Bearer ${GetLocalStorag(
      "UserToken"
    )}`;
    request(
      endpoints.ProfileData.GetProfileData.method,
      endpoints.ProfileData.GetProfileData.url
    )
      .then((response) => {
        setUserData(response.data.data.user);
        setState({
          ...initState,
          user: {
            address: response.data.data.user.wallet_address,
            avatar: response.data.data.user.avatar,
            name: response.data.data.user.name,
            bio: response.data.data.user.bio,
            cover: response.data.data.user.cover,
            twitter: response.data.data.user.twitter,
            facebook: response.data.data.user.facebook,
            portfolio: response.data.data.user.portfolio,
            linkedin: response.data.data.user.linkedin,
            instagram: response.data.data.user.instagram,
            custom_url: response.data.data.user.custom_url,
            following_count: response.data.data.user.following_count,
            followers_count: response.data.data.user.followers_count,
          },
          loading: false,
        });

        setLoading(false);
      })
      .catch((error) => {
        localStorage.removeItem("connectStatus");
        localStorage.removeItem("UserToken");
        setIsToken(false);
        setLoading(false);
      });
    try {
      /*  request to get followings data | khaled Mofeed  */
      const get_followings = await Promise.all([
        request(
          endpoints.Following.GetFollowings.method,
          endpoints.Following.GetFollowings.url,
          {
            params: {
              getAll: 1,
            },
          }
        ),
      ]);

      /*  request to get followers data | khaled Mofeed  */
      const get_followers = await Promise.all([
        request(
          endpoints.Following.GetFollowers.method,
          endpoints.Following.GetFollowers.url,
          {
            params: {
              getAll: 1,
            },
          }
        ),
      ]);
      /*  set the data of followers & followers in state to use it | khaled Mofeed  */
      setFollowData({
        followings: get_followings[0]?.data?.data?.followings,
        followers: get_followers[0]?.data?.data?.followers,
      });
      /*  request to get Event data | khaled Mofeed  */
      const get_Event = await Promise.all([
        request(
          endpoints.Event.GetAllEvents.method,
          endpoints.Event.GetAllEvents.url + `?perPage=${HTTP_PER_PAGE}`,
          {
            params: {
              getAll: 1,
            },
          }
        ),
      ]);
      setEvent({
        event: get_Event[0]?.data?.data?.events,
      });
      /*  request to get Categories data | khaled Mofeed  */
      const get_EventCategories = await Promise.all([
        request(
          endpoints.Event.GetCategories.method,
          endpoints.Event.GetCategories.url,
          {
            params: {
              getAll: 1,
            },
          }
        ),
      ]);
      setEventCategories({
        eventCategories: get_EventCategories[0]?.data?.data?.categories,
      });
      /*  request to get Owner Of Nft data | khaled Mofeed  */
      const get_OwnerOfNft = await Promise.all([
        request(
          endpoints.Marketplace.OwnerOf.method,
          endpoints.Marketplace.OwnerOf.url,
          {
            params: {
              getAll: 1,
            },
          }
        ),
      ]);
      setOwnerOfNft({
        ownerOfNft: get_OwnerOfNft[0]?.data?.data?.nfts,
      });
      /*  use Helper getPaginationAttributes to handle the data of paginations | khaled Mofeed  */
      const paginationOwnerOfNft = getPaginationAttributes(
        get_OwnerOfNft[0]?.data?.data
      );
      setPagination_OptionsOwn({
        Pagination_options: paginationOwnerOfNft.options,
        Pagination_Count: paginationOwnerOfNft.count,
        Pagination_Total: paginationOwnerOfNft.total,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  /*  request to get Event data by fillter | khaled Mofeed  */
  const getEventByFillter = async () => {
    /*  Define the variables to be used in the filter | khaled Mofeed  */
    let filter = "";
    /*  Define the variables to be used in the filter | khaled Mofeed  */
    const queryParams = {
      perPage,
      page,
      categories: CategoriesId,
      locations: location,
      date_from: date_From,
      date_to: date_To,
      keyword,
      history,
      owned,
    };
    /* Give each variable its own value | khaled Mofeed  */
    for (const key in queryParams) {
      const value = queryParams[key];
      if (value) {
        if (Array.isArray(value)) {
          filter += value.map((val) => `${key}[]=${val}`).join("&");
        } else {
          filter += `${key}=${value}`;
        }
        filter += "&";
      }
    }
    /* Return variables in a single handle variable | khaled Mofeed  */
    filter = filter.slice(0, -1); // remove the last '&'
    const get_Event_Fillter = await Promise.all([
      request(
        endpoints.Event.GetAllEvents.method,
        endpoints.Event.GetAllEvents.url + `?${filter}`,
        {
          params: {
            getAll: 1,
          },
        }
      ),
    ]);
    setEventFillter({
      eventFillter: get_Event_Fillter[0]?.data?.data?.events,
    });
    /*  use Helper getPaginationAttributes to handle the data of paginations | khaled Mofeed  */
    const paginationAttributes = getPaginationAttributes(
      get_Event_Fillter[0]?.data?.data
    );
    setPagination_options({
      Pagination_options: paginationAttributes.options,
      Pagination_Count: paginationAttributes.count,
      Pagination_Total: paginationAttributes.total,
    });
  };
  /*  request to get NFT data by fillter | khaled Mofeed  */
  const getAllNftByFillter = async () => {
    let filterNFT = "";
    /*  Define the variables to be used in the filter | khaled Mofeed  */
    const queryParams = {
      per_page: per_Page,
      page: inPage,
      category_id: CategoriesIdNft,
      location: location,
      filter: fillterKeyword,
      status: statusNft,
      price_from: price_from,
      price_to: price_to,
      condition: condition,
    };
    /* Give each variable its own value | khaled Mofeed  */
    for (const key in queryParams) {
      const value = queryParams[key];
      if (value) {
        if (Array.isArray(value)) {
          filterNFT += value.map((val) => `${key}[]=${val}`).join("&");
        } else {
          filterNFT += `${key}=${value}`;
        }
        filterNFT += "&";
      }
    }
    /* Return variables in a single handle variable | khaled Mofeed  */
    filterNFT = filterNFT.slice(0, -1); // remove the last '&'

    const get_All_Nft_Fillter = await Promise.all([
      request(
        endpoints.Marketplace.AllInNftWithOutAuth.method,
        endpoints.Marketplace.AllInNftWithOutAuth.url + `?${filterNFT}`,
        {
          params: {
            getAll: 1,
          },
        }
      ),
    ]);
    setAllNftFillter({
      allNftFillter: get_All_Nft_Fillter[0]?.data?.data?.nfts,
    });
    /*  request to get NFT data Of A_Section For Map | khaled Mofeed  */
    const get_Nft_A_Section = await Promise.all([
      request(
        endpoints.Marketplace.AllInNftWithOutAuth.method,
        endpoints.Marketplace.AllInNftWithOutAuth.url + A_SECTION,
        {
          params: {
            getAll: 1,
          },
        }
      ),
    ]);
    setNft_A_Section(get_Nft_A_Section[0]?.data?.data?.nfts);
    /*  request to get NFT data Of B_Section For Map | khaled Mofeed  */
    const get_Nft_B_Section = await Promise.all([
      request(
        endpoints.Marketplace.AllInNftWithOutAuth.method,
        endpoints.Marketplace.AllInNftWithOutAuth.url + B_SECTION,
        {
          params: {
            getAll: 1,
          },
        }
      ),
    ]);
    setNft_B_Section(get_Nft_B_Section[0]?.data?.data?.nfts);
    /*  request to get NFT data Of C_Section For Map | khaled Mofeed  */
    const get_Nft_C_Section = await Promise.all([
      request(
        endpoints.Marketplace.AllInNftWithOutAuth.method,
        endpoints.Marketplace.AllInNftWithOutAuth.url + C_SECTION,
        {
          params: {
            getAll: 1,
          },
        }
      ),
    ]);
    setNft_C_Section(get_Nft_C_Section[0]?.data?.data?.nfts);
    /*  request to get NFT data Of D_Section For Map | khaled Mofeed  */
    const get_Nft_D_Section = await Promise.all([
      request(
        endpoints.Marketplace.AllInNftWithOutAuth.method,
        endpoints.Marketplace.AllInNftWithOutAuth.url + D_SECTION,
        {
          params: {
            getAll: 1,
          },
        }
      ),
    ]);
    setNft_D_Section(get_Nft_D_Section[0]?.data?.data?.nfts);
    /*  request to get NFT data Of E_Section For Map | khaled Mofeed  */
    const get_Nft_E_Section = await Promise.all([
      request(
        endpoints.Marketplace.AllInNftWithOutAuth.method,
        endpoints.Marketplace.AllInNftWithOutAuth.url + E_SECTION,
        {
          params: {
            getAll: 1,
          },
        }
      ),
    ]);
    setNft_E_Section(get_Nft_E_Section[0]?.data?.data?.nfts);
    /*  request to get NFT data Of F_Section For Map | khaled Mofeed  */
    const get_Nft_F_Section = await Promise.all([
      request(
        endpoints.Marketplace.AllInNftWithOutAuth.method,
        endpoints.Marketplace.AllInNftWithOutAuth.url + F_SECTION,
        {
          params: {
            getAll: 1,
          },
        }
      ),
    ]);
    setNft_F_Section(get_Nft_F_Section[0]?.data?.data?.nfts);
    /*  request to get NFT data Of G_Section For Map | khaled Mofeed  */
    const get_Nft_G_Section = await Promise.all([
      request(
        endpoints.Marketplace.AllInNftWithOutAuth.method,
        endpoints.Marketplace.AllInNftWithOutAuth.url + G_SECTION,
        {
          params: {
            getAll: 1,
          },
        }
      ),
    ]);
    setNft_G_Section(get_Nft_G_Section[0]?.data?.data?.nfts);
    /*  use Helper getPaginationAttributes to handle the data of paginations | khaled Mofeed  */
    const paginationAttributesNft = getPaginationAttributes(
      get_All_Nft_Fillter[0]?.data?.data
    );
    setPagination_Options({
      Pagination_options: paginationAttributesNft.options,
      Pagination_Count: paginationAttributesNft.count,
      Pagination_Total: paginationAttributesNft.total,
    });
  };
  useEffect(() => {
    if (isToken) {
      getProfileData();
    }
  }, [TOKEN_User, loading, isToken]);
  /*  useEffect to run getEventByFillter function by token | khaled Mofeed  */
  // useEffect(() => {
  //   if (isToken) {
  //     getEventByFillter();
  //   }
  // }, [
  //   TOKEN_User,
  //   page,
  //   CategoriesId,
  //   location,
  //   date_From,
  //   date_To,
  //   keyword,
  //   history,
  //   owned,
  //   loading,
  //   isToken,
  // ]);
  /*  useEffect to run getAllNftByFillter function || khaled Mofeed  */
  // useEffect(() => {
  //   getAllNftByFillter();
  // }, [
  //   inPage,
  //   CategoriesIdNft,
  //   location,
  //   fillterKeyword,
  //   statusNft,
  //   price_from,
  //   price_to,
  //   condition,
  //   per_Page,
  // ]);

  /*  run and export submitConnectMetaMask function | khaled Mofeed | 5/12/2022 */
  return submitConnectMetaMask;
}
