import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./style.css";

function CustomTable({ cb, currentPage, no }) {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const result = await cb(no);
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, [cb, currentPage, no]);

  return (
    <div className="bg-live table-container ">
      <Table className="purchasing-table">
        <thead>
          <tr>
            <th>DS Amount </th>
            <th>Purchase Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td className="table-no-data" colSpan={3}>
                No Data Found
              </td>
            </tr>
          ) : (
            data.map(({ id, amount, date, status = "Complete" }) => (
              <tr key={id}>
                <td>{amount}</td>
                <td>{date}</td>
                <td>
                  <span
                    className={
                      status === "Complete" || status === "Ready"
                        ? "purchase-state complete-purchase"
                        : status === "Claimed"
                        ? "purchase-state claimed-purchase"
                        : "purchase-state incomplete-purchase"
                    }
                  >
                    {status}
                  </span>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default CustomTable;
