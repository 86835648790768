import React from "react";
import { advantages } from "../../../../configs/digitalShare";

function DSAdvantages() {
  return (
    <div>
      <div className="what-is-ds-title">
        <h1>Venus Digital Share Advantages:</h1>
      </div>
      <div>
        <ul className="ds-advantages">
          {advantages.map((advantage, index) => (
            <li key={index}>{advantage}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DSAdvantages;
