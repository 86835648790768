import * as am4core from "@amcharts/amcharts4/core";

const revenueXYConfig = (isIncome) => {
  const IncomeColor = ["#438D57", "#72DA8E"];
  const ExpenseColor = ["#6E27B3", "#246CF9"];

  const gradient = new am4core.LinearGradient();
  const array = isIncome ? IncomeColor : ExpenseColor;

  array.forEach((color) => {
    gradient.addColor(am4core.color(color));
  });

  gradient.rotation = 180;
  return gradient;
};

export default revenueXYConfig;
