/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { ethers, utils } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useCallback, useMemo } from "react";
import ConnectWalletModal from "../pages/Layout/ConnectWalletModal";
import { GetLocalStorag, SaveLocalStorag } from "../Helper/Helper";
import config from "../configs";
import presaleAbi from "../configs/abis/presale-contract.json";
import nftAbi from "../configs/abis/nft-presale-contract.json";
import marketplaceAbi from "../configs/abis/marketplace-contract.json";
import tokenAbi from "../configs/abis/token.json";
import nftMarketplaceAbi from "../configs/abis/nft-marketplace-contract.json";
import revenueAbi from "../configs/abis/revenue-contract.json";
import { toast } from "react-hot-toast";

import "@rainbow-me/rainbowkit/styles.css";
import {
  darkTheme,
  getDefaultWallets,
  connectorsForWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  trustWallet,
  coinbaseWallet,
  metaMaskWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";

import { configureChains, createClient, WagmiConfig } from "wagmi";
import {
  arbitrum,
  goerli,
  bsc,
  mainnet,
  optimism,
  polygon,
  bscTestnet,
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

const { chains, provider, webSocketProvider } = configureChains(
  [
    bscTestnet,
    ...(process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true" ? [bscTestnet] : []),
  ],
  [publicProvider()]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      trustWallet({ projectId: "Venus", chains }),
      coinbaseWallet({ projectId: "Venus", chains }),
      metaMaskWallet({ projectId: "Venus", chains }),
      walletConnectWallet({ projectId: "Venus", chains }),
    ],
  },
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});

const AppContext = createContext(null);
const { ethereum } = window;

const chainId = config.get("smartContract.CHAIN_ID");
const chainRpcUrl = config.get("smartContract.CHAIN_RPC_URL");
const chainName = config.get("smartContract.CHAIN_NAME");
const chainCurrencySymbol = config.get("smartContract.CHAIN_CURRENCY_SYMBOL");

const chainBlockExplorerUrl = config.get(
  "smartContract.CHAIN_BLOCK_EXPLORER_URL"
);
const stakingContract = config.get("smartContract.CONTRACT_ADDRESS");
const presaleAdress = config.get("smartContract.CONTRACT_ADDRESS");
const nftAdress = config.get("smartContract.NFT_CONTRACT_ADDRESS");
const marketplaceAddress = config.get("smartContract.MARKETPLACE_ADDRESS");
const tokenAddress = config.get("smartContract.TOKEN_ADDRESS");
const nftMarketplaceAddress = config.get(
  "smartContract.NFT_MARKETPLACE_ADDRESS"
);
const revenueAddress = config.get("smartContract.REVENUE_ADDRESS");

/*  This custome hook for Connect Wallet by ConnectWallet | khaled Mofeed | 5/12/2022 */

/*  This Context for get Web3,
      nftItems,
      Connect Wallet
      Dis Connect Wallet,
      Update NetWork,
      Get Staking List,
      Get Contract,
      Update Stakeholder Data,
      Get Balance of wallet,
 | khaled Mofeed | 5/12/2022 */

const AppContextProvider = ({ children, theme }) => {
  const initState = {
    user: {
      address: null,
      balance: "0.0000",
      name: "",
      bio: "",
      avatar: "",
      cover: "",
      custom_url: "",
      twitter: "",
      facebook: "",
      portfolio: "",
      linkedin: "",
      instagram: "",
      following_count: "",
      followers_count: "",
    },
    follow: {
      followings: [],
      followers: [],
    },
    event: [],
    eventFillter: [],
    eventCategories: [],
    allNftFillter: [],
    Nft_A_Section: [],
    Nft_B_Section: [],
    Nft_C_Section: [],
    Nft_D_Section: [],
    Nft_E_Section: [],
    Nft_F_Section: [],
    Nft_G_Section: [],
    ownerOfNft: [],
    loading: true,
    provider: null,
    netWork: null,
    wrongNetwork: false,
    contracts: {
      token: {
        contract: null,
      },
      nft: {
        contract: null,
        data: {
          time: "",
          items: [],
          seletedNFT: null,
          lockedNFT: null,
          boostingPercentage: 0,
        },
        forceClass: "",
      },
      presale: {
        contract: null,
        data: {
          time: "",
          list: [],
          transactionsList: [],
          stakedDetails: {
            lockedLPs: "0.0000",
            totalLPs: "0.0000",
            nextUlocke: "00:00:00",
            unlockedLPs: "0.0000",
            unlocekdRewards: "0.0000",
            nftUnlockedDate: "N / A",
          },
          stakingRewards: "0.0000",
          stakeAgain: false,
        },
      },
      marketplace: {
        contract: null,
      },
      nftMarketplace: {
        contract: null,
      },
      revenue: {
        contract: null,
      },
    },
    getShortenAddress: (address) =>
      address.substring(0, 6) + "..." + address.substring(address.length - 4),
    connectWallet: () => setConnectWalletModal(true),
  };
  const [userData, setUserData] = useState(initState.user);
  const [followData, setFollowData] = useState(initState.follow);
  const [event, setEvent] = useState(initState.event);
  /*  This Status For Fillter Events| khaled Mofeed | 13/02/2023 */
  const [eventFillter, setEventFillter] = useState(initState.eventFillter);
  const [pagination_options, setPagination_options] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  /*  This Status For Fillter Events by Categories| khaled Mofeed | 14/02/2023 */
  const [eventCategories, setEventCategories] = useState(
    initState.eventCategories
  );
  const [location, setLocation] = useState([]);
  const [CategoriesId, setCategoriesId] = useState([]);
  const [date_From, setDate_From] = useState();
  const [date_To, setDate_To] = useState();
  const [keyword, setKeyword] = useState("");
  const [history, setHistory] = useState("0");
  const [owned, setOwned] = useState();
  /*  This Status For Fillter NFT| khaled Mofeed | 13/02/2023 */
  const [allNftFillter, setAllNftFillter] = useState(initState.allNftFillter);
  const [Nft_A_Section, setNft_A_Section] = useState(initState.Nft_A_Section);
  const [Nft_B_Section, setNft_B_Section] = useState(initState.Nft_B_Section);
  const [Nft_C_Section, setNft_C_Section] = useState(initState.Nft_C_Section);
  const [Nft_D_Section, setNft_D_Section] = useState(initState.Nft_D_Section);
  const [Nft_E_Section, setNft_E_Section] = useState(initState.Nft_E_Section);
  const [Nft_F_Section, setNft_F_Section] = useState(initState.Nft_F_Section);
  const [Nft_G_Section, setNft_G_Section] = useState(initState.Nft_G_Section);

  const [pagination_Options, setPagination_Options] = useState(0);
  const [per_Page, setPer_Page] = useState(10);
  const [inPage, setInPage] = useState(1);
  const [fillterKeyword, setFillterKeyword] = useState("");
  const [price_from, setPrice_from] = useState();
  const [price_to, setPrice_to] = useState();
  const [statusNft, setStatusNft] = useState();
  const [condition, setCondition] = useState();
  const [CategoriesIdNft, setCategoriesIdNft] = useState([]);
  /*  This Status For Owner of NFT of user profile | khaled Mofeed | 14/02/2023 */
  const [ownerOfNft, setOwnerOfNft] = useState(initState.eventCategories);
  const [pagination_OptionsOwn, setPagination_OptionsOwn] = useState(0);
  const [per_PageOwn, setPer_PageOwn] = useState(10);
  const [inPageOwn, setInPageOwn] = useState(1);
  const [isNetwork, setIsNetwork] = useState(true);
  const [state, setStates] = useState(initState);
  const setState = (newState) =>
    setStates((prevState) => ({ ...prevState, ...newState }));
  const [nftItems, setNftItems] = useState([]);
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  /*  This Status For Update Network Modal | khaled Mofeed | 4/03/2023 */
  const [showModalUpdateNetwork, setShowModalUpdateNetwork] = useState(false);
  const handleClose = () => setShowModalUpdateNetwork(false);
  const handleShow = () => setShowModalUpdateNetwork(true);

  const [TOKEN_User, setTOKEN_User] = useState(
    localStorage.getItem("UserToken")
  );
  const [isToken, setIsToken] = useState(false);
  const [loading, setLoading] = useState(false);
  // const provider = new ethers.providers.Web3Provider(ethereum);
  const setPresaleTime = (time) => {
    const updatedPresale = {
      ...state.contracts.presale,
      data: {
        ...state.contracts.presale.data,
        time,
      },
    };
    setState({ contracts: { ...state.contracts, presale: updatedPresale } });
  };

  const setNftTime = (time) => {
    const updatedNft = {
      ...state.contracts.nft,
      data: {
        ...state.contracts.nft.data,
        time,
      },
    };
    setState({ contracts: { ...state.contracts, nft: updatedNft } });
  };
  /*  This function use for  Get Web3 | khaled Mofeed | 5/12/2022 */
  const getWeb3 = async (account, walletConnectProvider) => {
    if (ethereum || walletConnectProvider) {
      try {
        const provider = new ethers.providers.Web3Provider(
          walletConnectProvider || ethereum,
          "any"
        );

        let address = account || ethereum.selectedAddress;
        if (!address) {
          const addressRequest = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          address = addressRequest[0];
        }

        let netWork = await provider.getNetwork();
        if (netWork.chainId !== Number(chainId)) {
          setIsNetwork(false);
          SaveLocalStorag("IsNetwork", false);
          await updateNetWork();
          netWork = await provider.getNetwork();
        } else {
          setIsNetwork(true);
          SaveLocalStorag("IsNetwork", true);
        }
        const balanceSelectedAddress = await provider
          .getBalance(address)
          .catch(() => {});

        setState({
          provider,
          netWork,
          user: {
            ...state.user,
            address,
            balance: String(utils.formatUnits(balanceSelectedAddress)).match(
              /^-?\d+(?:\.\d{0,4})?/
            )?.[0],
          },
          loading: false,
          contracts: {
            ...state.contracts,
            presale: {
              ...state.contracts.presale,
            },
          },
        });
      } catch (error) {
        console.log(error);
        const provider = new ethers.providers.Web3Provider(
          walletConnectProvider || ethereum,
          "any"
        );
        const netWork = await provider.getNetwork();
        setState({
          loading: false,
          netWork,
        });
      }
    }
  };
  /*  This function use for  Update NetWork | khaled Mofeed | 5/12/2022 */
  const updateNetWork = async () => {
    if (!ethereum) {
      toast.error(
        "Metamask is not installed, please install it to complete the process"
      );
      return;
    }

    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(chainId).toString(16)}`,
          },
        ],
      });
      setIsNetwork(true);
      SaveLocalStorag("IsNetwork", true);

      // handleClose();
    } catch (error) {
      // console.log("errorerror|||", error);
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: chainName,
              chainId: `0x${Number(chainId).toString(16)}`,
              rpcUrls: [chainRpcUrl],
              nativeCurrency: {
                name: chainCurrencySymbol,
                symbol: chainCurrencySymbol,
                decimals: 18,
              },
              blockExplorerUrls: [chainBlockExplorerUrl],
            },
          ],
        });
        setIsNetwork(false);
        SaveLocalStorag("IsNetwork", false);
      } catch (error) {
        console.log(error);
        toast.custom(
          <div className="switchFaild">
            <span>
              {" "}
              <img src="/img/metamask.png" alt="metamask" />
            </span>
            <div>
              <h6>Network switch faild</h6>
              <p>
                Please switch network manually to <br /> Smart Chain - Testnet{" "}
              </p>
            </div>
          </div>
        );
        // setIsNetwork(true);
      }
    }
  };
  /*  This function use for  Dis Connect Wallet | khaled Mofeed | 5/12/2022 */
  const disconnect = async () => {
    // if (state.user.address) {
    localStorage.removeItem("connectStatus");
    localStorage.removeItem("UserToken");
    setIsToken(false);
    setState({
      ...initState,
      user: {
        address: null,
        balance: "0.0000",
      },
      loading: false,
    });
    // } else if (GetLocalStorag("connectStatus") === "2") {
    //   const provider = new WalletConnectProvider({
    //     rpc: {
    //       [chainId]: chainRpcUrl,
    //     },
    //     bridge: "https://bridge.walletconnect.org",
    //   });

    //   await provider.disconnect();
    //   localStorage.removeItem("connectStatus");
    //   localStorage.removeItem("UserToken");

    //   setState({
    //     ...initState,
    //     user: {
    //       address: null,
    //       balance: "0.0000",
    //     },
    //     loading: false,
    //   });
    // }
  };
  useEffect(() => {
    if (!ethereum) {
      toast.error(
        "Metamask is not installed, please install it to complete the process"
      );
    } else {
      ethereum.on("accountsChanged", async (account) => {
        if (!account[0]) {
          setState({
            ...initState,
            user: {
              address: "",
              balance: "0.0000",
            },
            loading: false,
          });

          return;
        } else {
          setState({ loading: true });

          getWeb3(account[0]);
        }
      });

      ethereum.on("chainChanged", async (chainId) => {
        if (
          parseInt(chainId, 16) !== Number(config.get("smartContract.CHAIN_ID"))
        ) {
          setIsNetwork(false);
          SaveLocalStorag("IsNetwork", false);

          setState({
            loading: false,
            netWork: {
              ...state.netWork,
              chainId,
            },
          });
        } else {
          setIsNetwork(true);
          SaveLocalStorag("IsNetwork", true);
          if (state.user.address) {
            // setIsNetwork(false);

            setState({
              loading: true,
              netWork: {
                ...state.netWork,
                chainId,
              },
            });
            SaveLocalStorag("IsNetwork", true);
            getWeb3();
          }
        }
      });

      ethereum.on("disconnect", () => {
        setState({
          ...initState,
          user: {
            address: "",
            balance: "0.0000",
          },
          loading: false,
        });
      });

      return () => ethereum.removeAllListeners();
    }
  }, []);

  useEffect(() => {
    if (
      nftItems.length === 0 ||
      state.contracts.nft.lockedNFT?.tokenID ||
      !state.contracts.nft.contract
    )
      return;

    nftItems.forEach(async (item) => {
      const getApprovedAddress =
        await state.contracts.nft.contract?.getApproved(
          item.tokenID.toString()
        );

      if (getApprovedAddress === stakingContract) {
        setState({
          contracts: {
            ...state.contracts,
            nft: {
              ...state.contracts.nft,
              data: {
                ...state.contracts.nft.data,
                seletedNFT: nftItems.find(
                  (nftItem) =>
                    nftItem.tokenID.toString() === item.tokenID.toString()
                ),
              },
            },
          },
          loading: false,
        });
      }
    });
  }, [nftItems, state.contracts.nft.contract]);

  const getConnectedStatus = useCallback(async () => {
    if (state.user.address) {
      getWeb3(state.user.address);
    } else if (GetLocalStorag("connectStatus") === "2") {
      const provider = new WalletConnectProvider({
        rpc: {
          [chainId]: chainRpcUrl,
        },
        bridge: "https://bridge.walletconnect.org",
      });
      const connected = await provider.enable();

      getWeb3(connected[0], provider);
      provider.on("accountsChanged", (accounts) => {
        if (state.user.address !== accounts[0]) {
          setState({ loading: true });
          getWeb3(accounts[0], provider);
        }
      });

      // provider.on("chainChanged", async (chainId) => {
      //   if (chainId !== Number(chainId)) {
      //     // await provider.disconnect();
      //   }
      // });

      // provider.on("disconnect", () => {
      //   setState({
      //     ...initState,
      //     user: {
      //       address: null,
      //       balance: "0.0000",
      //     },
      //     loading: false,
      //   });
      //   SaveLocalStorag("connectStatus", 0);
      // });
    } else {
      setState({
        loading: false,
      });
    }
  }, [ethereum]);

  useEffect(() => {
    getConnectedStatus();
  }, [TOKEN_User]);

  useEffect(() => {
    const getNetwork = async () => {
      const provider = new ethers.providers.Web3Provider(ethereum);

      const netWork = await provider.getNetwork();
      setState({
        netWork,
        ...(ethereum.selectedAddress ? {} : { loading: false }),
      });
    };

    ethereum && getNetwork();
  }, []);

  const getPresaleTime = async () => {
    let time = "";
    if (!state?.user?.address) return time;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const presaleContract = new ethers.Contract(
      presaleAdress,
      presaleAbi,
      signer
    );
    try {
      const startingDate = await presaleContract.getStartingDate();
      const deadlineDate = await presaleContract.getDeadlineDate();
      const now = Math.floor(Date.now() / 1000);
      if (now < startingDate) {
        time = "before";
      } else if (now > deadlineDate) {
        time = "after";
      } else {
        time = "live";
      }
    } catch (error) {
      return time;
    }
    return time;
  };

  const getNftPresaleTime = async () => {
    let time = "";
    if (!state?.user?.address) return time;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const nftContract = new ethers.Contract(nftAdress, nftAbi, signer);

    try {
      const startingDate = await nftContract.getStartingDate();
      const maxSupply = await nftContract.getMaxSupply();
      const purchased = await nftContract.getTotalNFTPurchased();

      const now = Math.floor(Date.now() / 1000);
      if (now < startingDate) {
        time = "before";
      } else if (maxSupply <= purchased) {
        time = "after";
      } else {
        time = "live";
      }
    } catch (error) {
      return time;
    }
    return time;
  };
  /*  This function use for get data from contract Stack | khaled Mofeed | 5/12/2022 */
  const getContract = async (url, address, walletConnectProvider) => {
    if (ethereum || walletConnectProvider) {
      try {
        const contractFile = await fetch(url);
        const convertContractFileToJson = await contractFile.json();

        const provider = new ethers.providers.Web3Provider(
          walletConnectProvider || ethereum
        );
        const signer = provider.getSigner();
        return new ethers.Contract(address, convertContractFileToJson, signer);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getMarketplaceContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const marketplaceContract = new ethers.Contract(
      marketplaceAddress,
      marketplaceAbi,
      signer
    );
    return marketplaceContract;
  };

  const getTokenContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    return tokenContract;
  };

  const getNftMarketplaceContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const nftMarketplaceContract = new ethers.Contract(
      nftMarketplaceAddress,
      nftMarketplaceAbi,
      signer
    );
    return nftMarketplaceContract;
  };

  const getRevenueContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const revenueContract = new ethers.Contract(
      revenueAddress,
      revenueAbi,
      signer
    );
    return revenueContract;
  };

  const setTimes = async () => {
    const [presaleTime, nftPresaleTime] = await Promise.all([
      getPresaleTime(),
      getNftPresaleTime(),
    ]);
    const updatedNft = {
      ...state.contracts.nft,
      data: {
        ...state.contracts.nft.data,
        time: nftPresaleTime,
      },
    };
    const updatedPresale = {
      ...state.contracts.presale,
      data: {
        ...state.contracts.presale.data,
        time: presaleTime,
      },
    };
    const updatedMarketplace = {
      ...state.contracts.marketplace,
      contract: getMarketplaceContract(),
    };

    const updatedToken = {
      ...state.contracts.token,
      contract: getTokenContract(),
    };

    const updatedNftMarketplace = {
      ...state.contracts.nftMarketplace,
      contract: getNftMarketplaceContract(),
    };

    const updatedRevenue = {
      ...state.contracts.revenue,
      contract: getRevenueContract(),
    };

    setState({
      contracts: {
        token: updatedToken,
        marketplace: updatedMarketplace,
        nft: updatedNft,
        presale: updatedPresale,
        nftMarketplace: updatedNftMarketplace,
        revenue: updatedRevenue,
      },
    });
  };

  useEffect(() => {
    if (state.user.address) {
      setTimes();
    }
  }, [state.user.address]);
  useEffect(() => {
    if (localStorage.getItem("UserToken")) {
      setIsToken(true);
    } else {
      setIsToken(false);
    }
  }, [loading]);
  const api = useMemo(
    () => ({
      getWeb3,
      state,
      initState,
      nftItems,
      disconnect,
      updateNetWork,
      setState,
      userData,
      setUserData,
      followData,
      setFollowData,
      TOKEN_User,
      setTOKEN_User,
      loading,
      setLoading,
      event,
      setEvent,
      eventFillter,
      setEventFillter,
      perPage,
      setPerPage,
      page,
      setPage,
      pagination_options,
      setPagination_options,
      eventCategories,
      setEventCategories,
      CategoriesId,
      setCategoriesId,
      location,
      setLocation,
      date_From,
      setDate_From,
      date_To,
      setDate_To,
      keyword,
      setKeyword,
      history,
      setHistory,
      owned,
      setOwned,
      allNftFillter,
      setAllNftFillter,
      pagination_Options,
      setPagination_Options,
      inPage,
      setInPage,
      per_Page,
      setPer_Page,
      fillterKeyword,
      setFillterKeyword,
      statusNft,
      setStatusNft,
      price_to,
      setPrice_to,
      price_from,
      setPrice_from,
      condition,
      setCondition,
      CategoriesIdNft,
      setCategoriesIdNft,
      Nft_A_Section,
      setNft_A_Section,
      Nft_B_Section,
      setNft_B_Section,
      Nft_C_Section,
      setNft_C_Section,
      Nft_D_Section,
      setNft_D_Section,
      Nft_E_Section,
      setNft_E_Section,
      Nft_F_Section,
      setNft_F_Section,
      Nft_G_Section,
      setNft_G_Section,
      ownerOfNft,
      setOwnerOfNft,
      per_PageOwn,
      setPer_PageOwn,
      inPageOwn,
      setInPageOwn,
      pagination_OptionsOwn,
      setPagination_OptionsOwn,
      handleShow,
      handleClose,
      isNetwork,
      setIsNetwork,
      chainId,
      wagmiClient,
      chains,
      isToken,
      setIsToken,
      ethereum,
    }),
    [
      getWeb3,
      state,
      initState,
      nftItems,
      disconnect,
      updateNetWork,
      setState,
      userData,
      setUserData,
      followData,
      setFollowData,
      TOKEN_User,
      setTOKEN_User,
      loading,
      setLoading,
      event,
      setEvent,
      eventFillter,
      setEventFillter,
      perPage,
      setPerPage,
      page,
      setPage,
      pagination_options,
      setPagination_options,
      eventCategories,
      setEventCategories,
      CategoriesId,
      setCategoriesId,
      location,
      setLocation,
      date_From,
      setDate_From,
      date_To,
      setDate_To,
      keyword,
      setKeyword,
      history,
      setHistory,
      owned,
      setOwned,
      allNftFillter,
      setAllNftFillter,
      pagination_Options,
      setPagination_Options,
      inPage,
      setInPage,
      per_Page,
      setPer_Page,
      fillterKeyword,
      setFillterKeyword,
      statusNft,
      setStatusNft,
      price_to,
      setPrice_to,
      price_from,
      setPrice_from,
      condition,
      setCondition,
      CategoriesIdNft,
      setCategoriesIdNft,
      Nft_A_Section,
      setNft_A_Section,
      Nft_B_Section,
      setNft_B_Section,
      Nft_C_Section,
      setNft_C_Section,
      Nft_D_Section,
      setNft_D_Section,
      Nft_E_Section,
      setNft_E_Section,
      Nft_F_Section,
      setNft_F_Section,
      Nft_G_Section,
      setNft_G_Section,
      ownerOfNft,
      setOwnerOfNft,
      per_PageOwn,
      setPer_PageOwn,
      inPageOwn,
      setInPageOwn,
      pagination_OptionsOwn,
      setPagination_OptionsOwn,
      handleShow,
      handleClose,
      isNetwork,
      setIsNetwork,
      chainId,
      wagmiClient,
      chains,
      isToken,
      setIsToken,
      ethereum,
    ]
  );

  return (
    <AppContext.Provider value={api}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          theme={darkTheme({
            accentColor: "#ff71ab",
            accentColorForeground: "white",
            borderRadius: "large",
            fontStack: "system",
            overlayBlur: "small",
          })}
          coolMode
          chains={chains}
        >
          <ConnectWalletModal
            connectWalletModal={connectWalletModal}
            setConnectWalletModal={setConnectWalletModal}
          />
          {children}
        </RainbowKitProvider>
      </WagmiConfig>
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
